import React, { Fragment } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { css } from "@emotion/react"
import { CssNotMobile } from "../components/utils/responsive"
import { content_frame_desktop, frame_common } from "../components/utils/css"

const TITLE = "404エラー"
class Error404 extends React.Component {
  render() {
    return (
      <Fragment>
        <Layout location={this.props.location}>
          <Seo title={`${TITLE}`} slug={"/404"} />
          <section
            css={css`
              ${frame_common};
              ${CssNotMobile(content_frame_desktop)};
            `}
          >
            <div
              css={css`
                padding: 1rem;
                background-color: #fff;
                min-height: 33vh;
                text-align: center;
                h2 {
                  margin-bottom: 3rem;
                }
              `}
            >
              <h2>404 Not Found</h2>
              <span>お探しのページは存在しません。</span>
            </div>
          </section>
        </Layout>
      </Fragment>
    )
  }
}

export default Error404
